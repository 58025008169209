import * as React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'gatsby';
import Layout from '../components/layout';

import Seo from '../components/seo';

const ServiceError = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const error = params.get('error');
  const isUnauth = error >= 400 && error < 500;
  const isServer = error >= 500 && error <= 599;
  const message = isUnauth
    ? 'Parece que tu link ha expirado. Si ya completaste tu información, recuerda que no podrás cambiarla. De lo contrario, contacta a soporte al cliente para pedir un nuevo link.'
    : isServer
    ? 'Parece que ha ocurrido un error interno. Contacta a soporte para mas información'
    : 'Ocurrió un error con tu link! Contacta a soporte para mas información';
  return (
    <>
      <Seo title="Error en el Servicio" />
      <Layout>
        <Container className="error h-100" tag="section" fluid>
          <Row className="align-items-center h-100 justify-content-center">
            <Col xs={12} md={5}>
              <Card>
                <CardBody className="py-5 px-3">
                  <h1 className="error__title text-center text-uppercase">
                    Lo sentimos!
                  </h1>
                  <article className="mt-4 text-center">
                    <p className="error__paragraph mb-4 mt-2">{message}</p>
                    <Link
                      to="/client-support?source=client"
                      className="error__link text-uppercase d-block mb-2"
                    >
                      Soporte
                    </Link>
                    <Link
                      to="/"
                      className="error__link text-uppercase  d-block"
                    >
                      Ir al Inicio
                    </Link>
                  </article>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default ServiceError;
